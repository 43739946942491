@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?rozilk');
  src:  url('fonts/icomoon.eot?rozilk#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?rozilk') format('truetype'),
    url('fonts/icomoon.woff?rozilk') format('woff'),
    url('fonts/icomoon.svg?rozilk#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check-round:before {
  content: "\e90e";
}
.icon-warning:before {
  content: "\e90f";
}
.icon-check:before {
  content: "\e90b";
}
.icon-user:before {
  content: "\e905";
}
.icon-logo:before {
  content: "\e900";
}
.icon-paper-clip:before {
  content: "\e91c";
}
.icon-download:before {
  content: "\e910";
}
.icon-caret-down:before {
  content: "\e904";
}
.icon-whatsapp:before {
  content: "\e901";
}
.icon-add:before {
  content: "\e902";
}
.icon-arrow-down:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e906";
}
.icon-menu:before {
  content: "\e907";
}
.icon-search:before {
  content: "\e908";
}
.icon-edit:before {
  content: "\e909";
}
.icon-exit-to-app:before {
  content: "\e90a";
}
.icon-spinner:before {
  content: "\e90c";
}
.icon-person:before {
  content: "\e90d";
}
.icon-financial:before {
  content: "\e912";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-asterisk:before {
  content: "\eae9";
}
