@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");
@import "bootstrap/dist/css/bootstrap.min.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "./assets/variables.scss";
@import "./assets/icomoon/style.css";

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  margin: 0;
  position: relative;
  background-color: $mercury;
}

:root {
  font-size: 62.5%;
}

.row {
  margin: 0px;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar:horizontal{
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: $shadow;
}

::-webkit-scrollbar-thumb:hover {
  background: $primary;
}

.white-space-nowrap {
  white-space: nowrap;
}

.toast-container .ngx-toastr {
  box-shadow: 0px 3px 6px $shadow;
  font-size: 16px;
  width: 100%;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0px 3px 6px $shadow;
}

.toast-close-button {
  margin: 5px;
}

.toast-title {
  font-size: 18px;
}

.toast-error {
  background-color: #00000099;
}

.toast-success {
  background-color: $fern;
}

.toast-info {
  background-color: $primary;
}

.toast-warning {
  background-color: $casablanca;
}

.toast-progress {
  background-color: $manatee;
}

input {
  &:disabled {
    background-color: $alto;
  }
}

.tox-tinymce-aux {
  display: none;
}
