$primary:       #132711;
$secondary:     #3d6139;
$tertiary:      #6ea03b;
$solidWhite:    #FFFFFF;

$burntYellow:   #b69328;
$radicalRed:    #F63147;
$softPeach:     #EFE1E2;
$froly:         #F48989;
$fern:          #5CBC79;
$brightGreen:   #65FF00;
$whatsapp:      #59CE72;
$frenchGray:    #BABABC;

$casablanca:    #F9B657;
$navajo-white:  #FFE3A8;
$alto:          #DEDEDE;

$manatee:       #8E8E90;
$mercury:       #E8E8E8;

$shadow:        #00000029;
